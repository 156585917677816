const handlePrivacyAgreementCheckboxClick: (event: any) => void = event => {
  preventDefaultFunction(event);

  try {
    const privacyAgreementCheckbox: any = event?.currentTarget || event?.target;
    const privacyAgreementCheckboxContainer: any = privacyAgreementCheckbox?.closest('.privacyAgreementInput');
    const privacyAgreementCheckboxInput: any = document.getElementById('privacyAgreement');
    const privacyAgreementValue: number = parseInt(privacyAgreementCheckboxInput?.value);

    privacyAgreementCheckboxContainer.classList.toggle('checked');
    privacyAgreementCheckboxContainer.classList.remove('invalid');

    if (privacyAgreementValue === 0) {
      privacyAgreementCheckboxInput.value = '1';
    } else {
      privacyAgreementCheckboxInput.value = '0';
    }
  } catch (error) {
    console.error(error);
  }
};

const getIsValidPrivacyAgreement: (value: string) => boolean = value => {
  try {
    const checkedAsNumber: number = parseInt(value);

    if (checkedAsNumber) return true;

    return false;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const setPrivacyAgreementInputError: (privacyAgreementInputID: string) => void = privacyAgreementInputID => {
  try {
    const privacyAgreementInput: any = document.getElementById(privacyAgreementInputID);
    const parent: any = privacyAgreementInput?.closest('.privacyAgreementInput') || null;

    parent.classList.add('invalid');
  } catch (error) {
    console.error(error);
  }
};

const relocatePrivacyInputValueByIdToNewInputByIdAndReturnSuccess: (
  privacyInputID: string,
  newInputID: string
) => boolean = (privacyInputID, newInputID) => {
  try {
    const privacyInput: any = document.getElementById(privacyInputID);
    const newInput: any = document.getElementById(newInputID);
    const privacyInputValue: string = privacyInput?.value || '';

    newInput.value = privacyInputValue;
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};
